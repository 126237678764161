import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

import AppConfig from "../../config/config";

interface Props {
  collapsed?: boolean;
  setCollapsed?: (val: boolean) => void;
  className?: string;
}

function Menu({ collapsed, setCollapsed, className }: Props) {
  return (
    <Nav
      navbar={className ? false : true}
      className={`${className ? className : ``}`}
    >
      {/* <NavItem className="header-menu-item">
        <a
          href={AppConfig.BUY_ON_OS_URL}
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          BUY ON OS
        </a>
      </NavItem> */}
      <NavItem className="header-menu-item">
        <a
          href="https://www.acrocalypse.gg/#About"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          ABOUT
        </a>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="https://www.acrocalypse.gg/#Roadmap"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          ROADMAP
        </a>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="https://www.acrocalypse.gg/#Team"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          TEAM
        </a>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="https://www.acrocalypse.gg/#FAQ"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          FAQ
        </a>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="https://mint.acrocalypse.gg/"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          MINT
        </a>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="/"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link is-active"
        >
          CONSOLE
        </a>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="https://alpha.paperhands.gg/"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          ALPHA
        </a>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="https://marketplace.acrocalypse.gg/"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          MARKETPLACE
        </a>
      </NavItem>
      <NavItem className="header-menu-item">
        <a
          href="https://acrocalypse.gitbook.io/acrocalypse/"
          target="_blank"
          rel="noreferrer"
          className="header-menu-link"
        >
          WHITEPAPER
        </a>
      </NavItem>
    </Nav>
  );
}

export default Menu;
