import { FunctionComponent, ElementType, ComponentType } from "react";
import {
  Route,
  RouteProps,
  RouteComponentProps,
  StaticContext,
} from "react-router";
import PropTypes from "prop-types";

//Common Pages
import UnauthenticatedLayout from "../layouts/UnauthenticatedLayout";

interface AppRouteProps extends RouteProps {
  component: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ComponentType<any>;
  layout?: ElementType;
  accessControl?: string;
}

const AppRoute: FunctionComponent<AppRouteProps> = ({
  component: Component,
  layout: ComponentLayout = UnauthenticatedLayout,
  accessControl = "any",
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <ComponentLayout {...props}>
          <Component {...props} />
        </ComponentLayout>
      )}
    />
  );
};

AppRoute.propTypes = {
  children: PropTypes.element,
  layout: PropTypes.any,
  component: PropTypes.any,
  accessControl: PropTypes.string,
};

export default AppRoute;
