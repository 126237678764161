const development = {
  apiGateway: {
    BASE_URL: "https://stakingapi.acrocalypse.gg",
  },
};

const uat = {
  apiGateway: {
    BASE_URL: "https://stakingapi-uat.acrocalypse.gg",
  },
};

const production = {
  apiGateway: {
    BASE_URL: "https://stakingapi.acrocalypse.gg",
  },
};

let baseConfig =
  process.env.REACT_APP_STAGE === "production"
    ? production
    : process.env.REACT_APP_STAGE === "uat"
    ? uat
    : development;

const AppConfig = {
  REACT_APP_STAGE: process.env.REACT_APP_STAGE,
  GA_TRACKING_ID: "G-ZFSR55EP0T",
  BUY_ON_OS_URL: "https://opensea.io/collection/acrocalypse",
  REACT_APP_VERSION: 1,
  LOCAL_STORAGE_WALLET_KEY: "stakingWalletAddress",
  INFURA_ID: process.env.REACT_APP_INFURA_ID || "",
  REACT_APP_STAKING_CONTRACT_ADDRESS:
    process.env.REACT_APP_STAKING_CONTRACT_ADDRESS || "",
  REACT_APP_NFT_CONTRACT_ADDRESS:
    process.env.REACT_APP_NFT_CONTRACT_ADDRESS || "",
  EXPLORER_URL:
    process.env.REACT_APP_ETHERS_NETWORK === "rinkeby"
      ? "https://rinkeby.etherscan.io"
      : "https://www.etherscan.io",
  APPLICATION_START_DATE: "2022-08-02",
  APPLICATION_END_DATE: "2022-11-10 03:00:00",
  MAXIMUM_SELECT_NFT: 80,
  ...baseConfig,
};

export default AppConfig;
