import React from "react";
import { Container, Row, Col } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

// Static Assets
import InnerHeader from "../../../assets/images/inner_header@2x.gif";
import TotalCrocs from "../../../assets/images/total_crocs@2x.png";
import TotalPinacate from "../../../assets/images/total_pinacate@2x.png";
import TotalCruzers from "../../../assets/images/total_cruzers@2x.png";
import TotalDeserts from "../../../assets/images/total_deserts@2x.png";
import Coin4x4 from "../../../assets/images/cicon4x4@2x.png";
import Coin4x6 from "../../../assets/images/cicon4x6@2x.png";
import Coin4x5 from "../../../assets/images/cicon4x5@2x.png";
import Coin4x12 from "../../../assets/images/cicon4x12@2x.png";

// Import UI
import HarvestingDataBox from "./partials/HarvestingDataBox";
import { IHarvestDataResponse } from "../../../api/types/general";
import { getpercentageLabel } from "../../../utils/common-utils";

interface Props {
  harvestingData: IHarvestDataResponse | null;
}

function Harvesting({ harvestingData }: Props) {
  if (!harvestingData) {
    return null;
  }

  return (
    <>
      <div className="inner-banner inner-banner-sm z-over">
        <LazyLoadImage
          effect="blur"
          alt="Header Banner"
          src={InnerHeader}
          srcSet={InnerHeader}
        />
      </div>
      <section className="content-section content-section-sm z-over pb-0">
        <Container>
          <div className="title-box">
            <span className="title-box-top"></span>
            <h1 className="title-small">Harvesting</h1>
            <span className="title-box-bottom"></span>
          </div>
          <Row className="align-items-center">
            <Col lg={3} md={6} sm={12} className="">
              <HarvestingDataBox
                image={TotalCrocs}
                altText="TOTAL CROCS"
                labelHeading="TOTAL CROCS"
                labelSubHeading="HARVESTING"
                cardImage={Coin4x4}
                totalNumber={harvestingData.totalStaked.totalStaked}
                totalValue={`${getpercentageLabel(
                  harvestingData.totalStaked.totalStakedPercentage,
                  false
                )}`}
                oneWeekStat={harvestingData.totalStaked.oneWeekTotalStaked}
                isPositive={getpercentageLabel(
                  harvestingData.totalStaked.totalStakedPercentage,
                  true
                )}
              />
            </Col>
            <Col lg={3} md={6} sm={12} className="">
              <HarvestingDataBox
                image={TotalPinacate}
                altText="TOTAL PICANTE"
                labelHeading="TOTAL PICANTE"
                labelSubHeading="RUINS"
                cardImage={Coin4x6}
                totalNumber={harvestingData.totalPool3Staked.totalPool3Staked}
                totalValue={`${getpercentageLabel(
                  harvestingData.totalPool3Staked.totalPool3StakedPercentage,
                  false
                )}`}
                oneWeekStat={
                  harvestingData.totalPool3Staked.oneWeekTotalPool3Staked
                }
                isPositive={getpercentageLabel(
                  harvestingData.totalPool3Staked.totalPool3StakedPercentage,
                  true
                )}
              />
            </Col>
            <Col lg={3} md={6} sm={12} className="">
              <HarvestingDataBox
                image={TotalCruzers}
                altText="TOTAL CRUZERS"
                labelHeading="TOTAL CRUZERS"
                labelSubHeading="DEMISE"
                cardImage={Coin4x5}
                totalNumber={harvestingData.totalPool2Staked.totalPool2Staked}
                totalValue={`${getpercentageLabel(
                  harvestingData.totalPool2Staked.totalPool2StakedPercentage,
                  false
                )}`}
                oneWeekStat={
                  harvestingData.totalPool2Staked.oneWeekTotalPool2Staked
                }
                isPositive={getpercentageLabel(
                  harvestingData.totalPool2Staked.totalPool2StakedPercentage,
                  true
                )}
              />
            </Col>
            <Col lg={3} md={6} sm={12} className="">
              <HarvestingDataBox
                image={TotalDeserts}
                altText="TOTAL DESERT"
                labelHeading="TOTAL DESERT"
                labelSubHeading="ROSE"
                cardImage={Coin4x12}
                totalNumber={harvestingData.totalPool1Staked.totalPool1Staked}
                totalValue={`${getpercentageLabel(
                  harvestingData.totalPool1Staked.totalPool1StakedPercentage,
                  false
                )}`}
                oneWeekStat={
                  harvestingData.totalPool1Staked.oneWeekTotalPool1Staked
                }
                isPositive={getpercentageLabel(
                  harvestingData.totalPool1Staked.totalPool1StakedPercentage,
                  true
                )}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Harvesting;
