import { ethers } from "ethers";
import AppConfig from "../config/config";
import * as AcrocalpyseStakingABI from "../artifacts/AcrocalpyseStaking.json";

export const stakeNftsHandler = async (
  web3Provider: ethers.providers.Web3Provider | null,
  signature: string,
  stakePool: number,
  tokenIds: Array<number>,
  rewardsPerDay: Array<number>,
  pool1RewardsPerDay: Array<number>
) => {
  try {
    const signer = web3Provider?.getSigner();

    const contract = new ethers.Contract(
      AppConfig.REACT_APP_STAKING_CONTRACT_ADDRESS,
      AcrocalpyseStakingABI.abi,
      signer
    );

    const tx = await contract.stake(
      signature,
      stakePool,
      tokenIds,
      rewardsPerDay.map((reward) => ethers.utils.parseEther(reward.toString())),
      pool1RewardsPerDay.map((reward) =>
        ethers.utils.parseEther(reward.toString())
      )
    );

    return {
      success: true,
      tx,
    };
  } catch (err: any) {
    return {
      error: true,
      errorMessage:
        err.data && err.data.message ? err.data.message : "Error staking crocs",
    };
  }
};

export const claimNftsHandler = async (
  web3Provider: ethers.providers.Web3Provider | null,
  tokenIds: Array<number>
) => {
  try {
    const signer = web3Provider?.getSigner();

    const contract = new ethers.Contract(
      AppConfig.REACT_APP_STAKING_CONTRACT_ADDRESS,
      AcrocalpyseStakingABI.abi,
      signer
    );

    const tx = await contract.claim(tokenIds);

    return {
      success: true,
      tx,
    };
  } catch (err: any) {
    return {
      error: true,
      errorMessage:
        err.data && err.data.message
          ? err.data.message
          : "Error claiming rewards",
    };
  }
};

export const claimUnstakeNftsHandler = async (
  web3Provider: ethers.providers.Web3Provider | null,
  tokenIds: Array<number>
) => {
  try {
    const signer = web3Provider?.getSigner();

    const contract = new ethers.Contract(
      AppConfig.REACT_APP_STAKING_CONTRACT_ADDRESS,
      AcrocalpyseStakingABI.abi,
      signer
    );

    const tx = await contract.claimAndUnstake(tokenIds);

    return {
      success: true,
      tx,
    };
  } catch (err: any) {
    return {
      error: true,
      errorMessage:
        err.data && err.data.message
          ? err.data.message
          : "Error claiming rewards",
    };
  }
};

export const approveToken = async (
  web3Provider: ethers.providers.Web3Provider | null
) => {
  try {
    const signer = web3Provider?.getSigner();

    const contract = new ethers.Contract(
      AppConfig.REACT_APP_NFT_CONTRACT_ADDRESS,
      ["function setApprovalForAll(address operator, bool _approved) external"],
      signer
    );

    const tx = await contract.setApprovalForAll(
      AppConfig.REACT_APP_STAKING_CONTRACT_ADDRESS,
      true
    );

    return {
      success: true,
      tx,
    };
  } catch (err: any) {
    return {
      error: true,
      errorMessage:
        err.data && err.data.message ? err.data.message : "Error approving",
    };
  }
};

export const isStakingContractApproved = async (
  walletAddress: string,
  web3Provider: ethers.providers.Web3Provider | null
) => {
  try {
    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract(
      AppConfig.REACT_APP_NFT_CONTRACT_ADDRESS,
      [
        "function isApprovedForAll(address owner, address operator) external view returns (bool)",
      ],
      signer
    );

    const approved = await contract.isApprovedForAll(
      walletAddress,
      AppConfig.REACT_APP_STAKING_CONTRACT_ADDRESS
    );

    return {
      success: true,
      approved,
    };
  } catch (err: any) {
    return {
      error: true,
      errorMessage:
        err.data && err.data.message
          ? err.data.message
          : "Error fetching approval status",
    };
  }
};
