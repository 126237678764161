import DesertRose from "../assets/images/icons/btn-cicon1x1@2x.png";
import CruzersDemise from "../assets/images/icons/btn-cicon1x2@2x.png";
import PicanteRuins from "../assets/images/icons/btn-cicon1x3@2x.png";

const RouteConstants = {
  LANDING_PAGE: "/",
  HOME_PAGE: "/home",
  NOT_FOUND: "*",
};

export const NetworkId = {
  mainnet: "1",
  ethereum: "4",
  hardhat: "31337",
};

export const ModalStatus = {
  request: 0,
  error: 1,
  success: 2,
  apiPending: 3,
};

export const StakeType = {
  1: {
    label: "Desert Rose",
    value: "desert_rose",
    totalHoldingDays: 7,
    pool: 1,
    image: DesertRose,
  },
  2: {
    label: "Cruzers Demise",
    value: "cruzers_demise",
    totalHoldingDays: 45,
    pool: 2,
    image: CruzersDemise,
  },
  3: {
    label: "Picante Ruins",
    value: "picante_ruins",
    totalHoldingDays: 90,
    pool: 3,
    image: PicanteRuins,
  },
};

export default RouteConstants;
