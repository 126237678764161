import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AppConfig from "../../../../config/config";

import { ModalStatus } from "../../../../config/Constant";

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
  modalStatus: number;
  modalErrorMessage: Array<string>;
  transaction: any;
}

function ConfirmationModal({
  isOpen,
  modalToggler,
  modalErrorMessage,
  modalStatus,
  transaction,
}: Props) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => modalToggler(!isOpen)}
      className="modal-dialog-centered"
    >
      <div className="modal-content-wrapper">
        <ModalHeader toggle={() => modalToggler(!isOpen)}></ModalHeader>
        <ModalBody className="text-center">
          {modalStatus === ModalStatus.request && (
            <div className="w-full pb-4">
              <div className="mx-auto size-100 vc mb-25">
                <div className="spinner3"></div>
              </div>
              <h3>Waiting for Confirmation</h3>
              <p className="text-white-50 fw-medium">
                Confirm this transaction in your wallet
              </p>
            </div>
          )}

          {modalStatus === ModalStatus.success && (
            <div className="w-full pb-4">
              <h3>Transaction submitted</h3>
              <a
                href={`${AppConfig.EXPLORER_URL}/tx/${transaction.hash}`}
                className="link-view"
                target="_blank"
                rel="noreferrer"
              >
                View on explorer
              </a>
            </div>
          )}

          {modalStatus === ModalStatus.error && (
            <div className="w-full pb-3">
              <h3>Error</h3>
              {modalErrorMessage.map((obj, key) => (
                <p className="text-white-50 fw-medium" key={key}>
                  {obj}
                </p>
              ))}
              <div className="w-full mt-4">
                <button
                  onClick={() => modalToggler(!isOpen)}
                  className="button button-blue button-rounded button-lg button-noborder"
                >
                  Dismiss
                </button>
              </div>
            </div>
          )}
        </ModalBody>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
