import { useEffect, useState, useContext } from "react";
import { Container, Nav, Navbar, NavbarToggler, Collapse } from "reactstrap";
import { Link } from "react-scroll";

import { Context, web3Modal } from "../../contexts/ContextProvider";

// Import Static Images
import LogoIcon from "../../assets/images/logo@2x.png";

// Static UI imports
import Menu from "./Menu";

// Import Feather Icons
// import { Send, Twitter } from 'react-feather'

function Header() {
  const { walletAddress, showLoader, connectWalletHandler, disconnectWallet } =
    useContext(Context);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  useEffect(() => {
    const header = document.getElementById("appHeader");
    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("is-sticky");
        } else {
          header.classList.remove("is-sticky");
        }
      });
    }
  });

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWalletHandler();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <header className="header-horizontal header-horizontal-sm" id="appHeader">
      <Container>
        <div className="w-100 d-flex align-items-center justify-content-between">
          <Link
            to="Home"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="cursor-pointer"
          >
            <img src={LogoIcon} className="header-logo" alt="Acrocalypse" />
          </Link>
          <Nav className="header-menu header-menu-sm">
            <Menu className="header-menu-list" />
          </Nav>
          <Navbar
            color="faded"
            light
            className={`${collapsed ? `navigation-active` : ``}`}
          >
            <NavbarToggler
              className=""
              onClick={() => setCollapsed(!collapsed)}
            />
            <Collapse className="responsive-navigation" isOpen={collapsed}>
              <div
                className="close-menu"
                style={{ display: "flex" }}
                data-uw-styling-context="true"
                onClick={() => setCollapsed(!collapsed)}
              >
                <span
                  className="icon-close"
                  data-uw-styling-context="true"
                ></span>
              </div>
              <Menu setCollapsed={setCollapsed} collapsed={collapsed} />
            </Collapse>
            {collapsed && (
              <div
                className="menu-overlay"
                onClick={() => setCollapsed(!collapsed)}
              ></div>
            )}
          </Navbar>
          {!walletAddress && (
            <button
              type="button"
              className={`btn-mint-a-croc cursor-pointer ${
                showLoader ? `is-loading` : ``
              }`}
              onClick={() => connectWalletHandler()}
            >
              CONNECT
            </button>
          )}

          {walletAddress && (
            <button
              type="button"
              className="btn-mint-a-croc cursor-pointer"
              onClick={disconnectWallet}
            >
              DISCONNECT
            </button>
          )}
        </div>
      </Container>
    </header>
  );
}

export default Header;
