import { Progress } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

// Import Feather Icons
import { Plus, Check } from "react-feather";

// Static Assets
import Clock from "../../../../assets/images/icons/clock@2x.png";
import PaperIcon from "../../../../assets/images/icons/paper_animup.gif";
import Paper2Icon from "../../../../assets/images/icons/paper_noanim.png";

interface Props {
  mainImage: string;
  coinImage: string;
  labelNumber: string;
  days: string;
  paperCount: string;
  labelText: string;
  propgressBarPerValue: string;
  propgressBarValue: string;
  initialPlus?: boolean;
  isSelected?: boolean;
  isSelectedDisabled?: boolean;
  isTermActive?: boolean;
  tokenSelectHandler: () => void;
  poolClass: string;
}

const StakedDataBox = (props: Props) => {
  return (
    <div
      className={`staked-list-wrap ${props.isSelected ? `is-selected` : ``}`}
      role="button"
      onClick={() =>
        !props.isSelectedDisabled ? props.tokenSelectHandler() : null
      }
    >
      <div className="image-block-wrapper">
        {!props.isSelected && (
          <button
            type="button"
            className="btn-plus position-absolute top-10 right-10 z-over"
            onClick={() => props.tokenSelectHandler()}
            disabled={props.isSelectedDisabled ? true : false}
          >
            <Plus size={22} />
          </button>
        )}

        {props.isSelected && (
          <button
            type="button"
            className="btn-plus position-absolute top-10 right-10 z-over is-selected"
            onClick={() => props.tokenSelectHandler()}
          >
            <Check size={22} />
          </button>
        )}
        <LazyLoadImage
          effect="blur"
          alt="Staked Image"
          src={props.mainImage}
          srcSet={props.mainImage}
        />
      </div>

      <div className="staked-list-details">
        <p className="fs-13">{props.labelNumber}</p>
        <div className="d-flex align-items-center days">
          <span className="size-16 mt-0">
            <LazyLoadImage
              effect="blur"
              alt="Clock"
              src={Clock}
              srcSet={Clock}
            />
          </span>
          {props.days}
        </div>

        <div className="d-flex align-items-center mb-8">
          <span className="size-22 mt-0">
            <LazyLoadImage
              effect="blur"
              alt="Coin4x5"
              src={props.coinImage}
              srcSet={props.coinImage}
            />
          </span>
          <label className={`label ${props.poolClass}`}>
            {props.labelText}
          </label>
        </div>

        <div className="d-flex align-items-center numbers mb-3">
          {!props.isTermActive && (
            <span className="size-16 mt-0">
              <LazyLoadImage
                effect="blur"
                alt="Clock"
                src={PaperIcon}
                srcSet={PaperIcon}
              />
            </span>
          )}
          {props.isTermActive && (
            <span className="size-16 mt-0">
              <LazyLoadImage
                effect="blur"
                alt="Clock"
                src={Paper2Icon}
                srcSet={Paper2Icon}
              />
            </span>
          )}
          {props.paperCount}
        </div>

        <div className="w-100 bar-block">
          <Progress value={props.propgressBarPerValue} />
          <div className="count">{props.propgressBarValue}</div>
        </div>
      </div>
    </div>
  );
};

export default StakedDataBox;
