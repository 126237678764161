import { useCallback, useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";

import {
  IWalletOwnedNftObj,
  IWalletStakedNftObj,
} from "../../../api/types/general";
import GeneralApi from "../../../api/GeneralApi";
import { getCatchError } from "../../../utils/errorHandler";
import { Context } from "../../../contexts/ContextProvider";

import Coin2x2 from "../../../assets/images/icons/cicon2x2@2x.png";
import DesertRose from "../../../assets/images/icons/btn-cicon1x1@2x.png";
import CruzersDemise from "../../../assets/images/icons/btn-cicon1x2@2x.png";
import PicanteRuins from "../../../assets/images/icons/btn-cicon1x3@2x.png";

// Import UI
import StakedDataBox from "./partials/StakedDataBox";
import ConfirmationModal from "./partials/ConfirmationModal";
import CustomToastComponent from "./partials/CustomToastComponent";
import { ModalStatus, StakeType } from "../../../config/Constant";
import AppConfig from "../../../config/config";
import {
  approveToken,
  isStakingContractApproved,
  claimNftsHandler,
  stakeNftsHandler,
  claimUnstakeNftsHandler,
} from "../../../utils/metamask-utils";
import { confirmAlert } from "react-confirm-alert";

interface StakedCardProps {
  fetchStakeDetail: (onlyUpdateHarvestData: boolean) => {};
}

function StakedCard({ fetchStakeDetail }: StakedCardProps) {
  const {
    walletAddress,
    updateRequestInProcess,
    updateShowLoader,
    web3Provider,
  } = useContext(Context);
  const [tokenClaimLoader, setTokenClaimLoader] = useState<boolean>(false);
  const [tokenClaimUnstakeLoader, setTokenClaimUnstakeLoader] =
    useState<boolean>(false);
  const [tokenStakeType, setTokenStakeType] = useState<number>(0);
  const [fetchAccountSummaryLoader, setFetchAccountSummaryLoader] =
    useState<boolean>(false);
  const [ownedNftsList, setOwnedNftsList] = useState<Array<IWalletOwnedNftObj>>(
    []
  );
  const [stakedNftsList, setStakedNftsList] = useState<
    Array<IWalletStakedNftObj>
  >([]);
  const [selectedTokenIdsList, setSelectedTokenIdsList] = useState<
    Array<string>
  >([]);
  const [selectedClaimTokenIdsList, setSelectedClaimTokenIdsList] = useState<
    Array<string>
  >([]);
  const [isTokenApproved, setIsTokenApproved] = useState<boolean>(true);
  const [approveTokenLoader, setApproveTokenLoader] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState<number>(ModalStatus.request); // 0 stable, 1 error, 2 success
  const [modalErrorMessage, setModalErrorMessage] = useState<Array<string>>([]); // 0 stable, 1 error, 2 success
  const [transaction, setTransaction] = useState<any>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  const confirmTokenStakeHandler = (pool: keyof typeof StakeType) => {
    confirmAlert({
      title: "Confirm",
      message: `Your crocs are about to enter ${StakeType[pool].label}... They'll be unable to return for at least ${StakeType[pool].totalHoldingDays} Days!`,
      buttons: [
        {
          label: "Yes",
          onClick: () => tokenStakeHandler(pool),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const getPoolColorClass = (poolIndex: keyof typeof StakeType) => {
    let returnVal = "";

    if (poolIndex === StakeType[1].pool) {
      returnVal = "label-pool-1";
    }

    if (poolIndex === StakeType[2].pool) {
      returnVal = "label-pool-2";
    }

    if (poolIndex === StakeType[3].pool) {
      returnVal = "label-pool-3";
    }

    return returnVal;
  };

  const validateEndDateDuration = (stakeType: keyof typeof StakeType) => {
    const estimateEndDate = moment
      .utc()
      .add(StakeType[stakeType].totalHoldingDays, "days");
    const applicationEndDate = moment.utc(AppConfig.APPLICATION_END_DATE);

    if (
      stakeType !== StakeType[1].pool &&
      estimateEndDate.isAfter(applicationEndDate)
    ) {
      return true;
    }

    return false;
  };

  const approveTokenHandler = async () => {
    setApproveTokenLoader(true);
    setModalStatus(ModalStatus.request);
    setConfirmModalOpen(!confirmModalOpen);
    updateRequestInProcess(false);
    updateShowLoader(false);

    const response = await approveToken(web3Provider);

    if (response.error) {
      setModalStatus(ModalStatus.error);
      setModalErrorMessage([response.errorMessage]);
      setApproveTokenLoader(false);
      return;
    }

    setTransaction(response.tx);
    setModalStatus(ModalStatus.success);

    // Pending status for transaction
    try {
      updateRequestInProcess(true);
      updateShowLoader(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const receipt = await response.tx.wait();
      updateRequestInProcess(false);
      updateShowLoader(false);

      setIsTokenApproved(true);

      toast.success(
        <CustomToastComponent
          message="NFT transfer approved successfully"
          link={`${AppConfig.EXPLORER_URL}/tx/${response.tx.hash}`}
        />
      );
    } catch (error) {
    } finally {
      updateRequestInProcess(false);
      updateShowLoader(false);
      setApproveTokenLoader(false);
    }
  };

  const checkStakingContractApproval = async () => {
    setApproveTokenLoader(true);
    setIsTokenApproved(false);

    if (walletAddress && web3Provider) {
      try {
        const response = await isStakingContractApproved(
          walletAddress,
          web3Provider
        );

        if (response.approved) {
          setIsTokenApproved(true);
        }
      } catch (error: any) {
        toast.error(<CustomToastComponent message={error.message} />);
      }
    }

    setApproveTokenLoader(false);
  };

  const getTotalRewards = () => {
    let returnCount = 0;

    if (stakedNftsList.length > 0) {
      for (let i = 0; i < stakedNftsList.length; i++) {
        returnCount += stakedNftsList[i].unclaimedRewards;
      }
    }

    return returnCount;
  };

  const tokenClaimUnstakeHandler = async () => {
    try {
      if (selectedClaimTokenIdsList.length <= 0) {
        toast.error(
          <CustomToastComponent message={"Please select crocs to proceed"} />,
          {
            autoClose: 5000,
          }
        );
        return;
      }

      let isError = false;

      for (let i = 0; i < stakedNftsList.length; i++) {
        const stakedObj = stakedNftsList[i];

        if (
          !isError &&
          selectedClaimTokenIdsList.includes(stakedObj.id) &&
          stakedObj.lockedUntilTime >= moment.utc().unix()
        ) {
          isError = true;
          break;
        }
      }

      if (isError) {
        toast.error(
          <CustomToastComponent message="Locked crocs cant be unstaked" />,
          {
            autoClose: 5000,
          }
        );

        return;
      }

      setTokenClaimUnstakeLoader(true);

      setModalStatus(ModalStatus.request);
      setConfirmModalOpen(!confirmModalOpen);
      updateRequestInProcess(false);
      updateShowLoader(false);

      const tokenStakeResponse = await claimUnstakeNftsHandler(
        web3Provider,
        selectedClaimTokenIdsList.map((x) => parseInt(x))
      );

      if (tokenStakeResponse.error) {
        setModalStatus(ModalStatus.error);
        setModalErrorMessage([tokenStakeResponse.errorMessage]);
        setTokenClaimUnstakeLoader(false);
        return;
      }

      setTransaction(tokenStakeResponse.tx);
      setModalStatus(ModalStatus.success);

      // Pending status for transaction
      try {
        updateRequestInProcess(true);
        updateShowLoader(true);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const receipt = await tokenStakeResponse.tx.wait();

        updateRequestInProcess(false);
        updateShowLoader(false);

        setIsTokenApproved(true);

        toast.success(
          <CustomToastComponent
            message="Crocs claimed &amp; unstaked successfully"
            link={`${AppConfig.EXPLORER_URL}/tx/${tokenStakeResponse.tx.hash}`}
          />
        );
      } catch (error) {
      } finally {
        updateRequestInProcess(false);
        updateShowLoader(false);
        setTokenClaimUnstakeLoader(false);
      }

      setSelectedClaimTokenIdsList([]);

      await fetchAccountSummary();
      await fetchStakeDetail(true);
    } catch (err) {
      toast.error(<CustomToastComponent message={getCatchError(err)[0]} />, {
        autoClose: 5000,
      });
    } finally {
      setTokenClaimUnstakeLoader(false);
    }
  };

  const tokenClaimHandler = async () => {
    try {
      if (selectedClaimTokenIdsList.length <= 0) {
        toast.error(
          <CustomToastComponent message={"Please select crocs to claim"} />,
          {
            autoClose: 5000,
          }
        );
        return;
      }

      setTokenClaimLoader(true);

      setModalStatus(ModalStatus.request);
      setConfirmModalOpen(!confirmModalOpen);
      updateRequestInProcess(false);
      updateShowLoader(false);

      const tokenStakeResponse = await claimNftsHandler(
        web3Provider,
        selectedClaimTokenIdsList.map((x) => parseInt(x))
      );

      if (tokenStakeResponse.error) {
        setModalStatus(ModalStatus.error);
        setModalErrorMessage([tokenStakeResponse.errorMessage]);
        setTokenClaimLoader(false);
        return;
      }

      setTransaction(tokenStakeResponse.tx);
      setModalStatus(ModalStatus.success);

      // Pending status for transaction
      try {
        updateRequestInProcess(true);
        updateShowLoader(true);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const receipt = await tokenStakeResponse.tx.wait();

        updateRequestInProcess(false);
        updateShowLoader(false);

        setIsTokenApproved(true);

        toast.success(
          <CustomToastComponent
            message="Crocs claimed successfully"
            link={`${AppConfig.EXPLORER_URL}/tx/${tokenStakeResponse.tx.hash}`}
          />
        );
      } catch (error) {
      } finally {
        updateRequestInProcess(false);
        updateShowLoader(false);
        setTokenClaimLoader(false);
      }

      await fetchAccountSummary(true);

      setSelectedClaimTokenIdsList([]);
    } catch (err) {
      toast.error(<CustomToastComponent message={getCatchError(err)[0]} />, {
        autoClose: 5000,
      });
    } finally {
      setTokenClaimLoader(false);
    }
  };

  const tokenStakeHandler = async (stakeType: number) => {
    try {
      if (selectedTokenIdsList.length <= 0) {
        toast.error(
          <CustomToastComponent message={"Please select crocs to stake"} />,
          {
            autoClose: 5000,
          }
        );
        return;
      }

      if (selectedTokenIdsList.length > AppConfig.MAXIMUM_SELECT_NFT) {
        toast.error(
          <CustomToastComponent
            message={`Maximum ${AppConfig.MAXIMUM_SELECT_NFT} can be staked in one transaction`}
          />,
          {
            autoClose: 5000,
          }
        );
        return;
      }

      const estimateEndDate = moment
        .utc()
        .add(
          StakeType[stakeType as keyof typeof StakeType].totalHoldingDays,
          "days"
        );
      const applicationEndDate = moment.utc(AppConfig.APPLICATION_END_DATE);

      if (
        stakeType !== StakeType[1].pool &&
        estimateEndDate.isAfter(applicationEndDate)
      ) {
        toast.error(
          <CustomToastComponent
            message={"Staking period is over the allowed period"}
          />,
          {
            autoClose: 5000,
          }
        );
        return;
      }

      setTokenStakeType(stakeType);

      const response = await GeneralApi.stakeNfts(
        walletAddress,
        selectedTokenIdsList.join(","),
        stakeType
      );

      setModalStatus(ModalStatus.request);
      setConfirmModalOpen(!confirmModalOpen);
      updateRequestInProcess(false);
      updateShowLoader(false);

      const tokenStakeResponse = await stakeNftsHandler(
        web3Provider,
        response.data.signature,
        response.data.stakePool,
        response.data.tokenIds,
        response.data.rewardsPerDay,
        response.data.pool1RewardsPerDay
      );

      if (tokenStakeResponse.error) {
        setModalStatus(ModalStatus.error);
        setModalErrorMessage([tokenStakeResponse.errorMessage]);
        setTokenStakeType(0);
        return;
      }

      setTransaction(tokenStakeResponse.tx);
      setModalStatus(ModalStatus.success);

      // Pending status for transaction
      try {
        updateRequestInProcess(true);
        updateShowLoader(true);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const receipt = await tokenStakeResponse.tx.wait();

        updateRequestInProcess(false);
        updateShowLoader(false);

        setIsTokenApproved(true);

        toast.success(
          <CustomToastComponent
            message="Token staked successfully"
            link={`${AppConfig.EXPLORER_URL}/tx/${tokenStakeResponse.tx.hash}`}
          />
        );
      } catch (error) {
      } finally {
        updateRequestInProcess(false);
        updateShowLoader(false);
      }

      setSelectedTokenIdsList([]);

      await fetchAccountSummary();
      await fetchStakeDetail(true);
    } catch (err) {
      toast.error(<CustomToastComponent message={getCatchError(err)[0]} />, {
        autoClose: 5000,
      });
    } finally {
      setTokenStakeType(0);
    }
  };

  const stakedTokenSelectHandler = (
    id: string,
    selectAll?: boolean,
    unselectAll?: boolean
  ) => {
    let newSelectedIdsList = [...selectedClaimTokenIdsList];

    if (selectAll && !unselectAll) {
      newSelectedIdsList = stakedNftsList.map((obj) => obj.id);
    } else {
      if (newSelectedIdsList.includes(id)) {
        newSelectedIdsList = newSelectedIdsList.filter(
          (tokenId) => tokenId !== id
        );
      } else {
        newSelectedIdsList.push(id);
      }
    }

    setSelectedClaimTokenIdsList(!unselectAll ? newSelectedIdsList : []);
  };

  const tokenSelectHandler = (
    id: string,
    selectAll?: boolean,
    unselectAll?: boolean
  ) => {
    let newSelectedIdsList = [...selectedTokenIdsList];

    if (unselectAll) {
      setSelectedTokenIdsList([]);
      return;
    }

    if (selectAll) {
      const newList = ownedNftsList.filter((obj) => {
        return newSelectedIdsList.filter((id) => {
          return obj.id !== id;
        });
      });

      const totalItemPush =
        ownedNftsList.length > AppConfig.MAXIMUM_SELECT_NFT
          ? AppConfig.MAXIMUM_SELECT_NFT - newSelectedIdsList.length
          : ownedNftsList.length - newSelectedIdsList.length;

      const finalPushItemList = newList.slice(0, totalItemPush);

      newSelectedIdsList = [
        ...newSelectedIdsList,
        ...finalPushItemList.map((obj) => obj.id),
      ];
    } else {
      if (newSelectedIdsList.includes(id)) {
        newSelectedIdsList = newSelectedIdsList.filter(
          (tokenId) => tokenId !== id
        );
      } else {
        newSelectedIdsList.push(id);
      }
    }

    setSelectedTokenIdsList(newSelectedIdsList);
  };

  const fetchAccountSummary = useCallback(
    async (getOnlyStakedList: boolean = false) => {
      try {
        setFetchAccountSummaryLoader(true);

        const response = await GeneralApi.getAccountNftsSummary(
          walletAddress,
          getOnlyStakedList ? 1 : 0
        );

        if (!getOnlyStakedList) {
          setOwnedNftsList(response.data.ownedNftsList);
        }
        setStakedNftsList(response.data.stakedNftsList);
      } catch (err) {
        toast.error(<CustomToastComponent message={getCatchError(err)[0]} />, {
          autoClose: 5000,
        });
      } finally {
        setFetchAccountSummaryLoader(false);
      }
    },
    [walletAddress]
  );

  useEffect(() => {
    if (walletAddress) {
      // check approval
      checkStakingContractApproval();
      fetchAccountSummary();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  return (
    <section className="content-section content-section-sm z-over pt-24">
      <Container>
        <Row className="">
          <Col lg={6} md={6} sm={12} className="">
            <Card className="harvesting-box">
              <div className="harvesting-box-top"></div>
              <CardHeader className="d-flex align-items-center justify-content-between border-0 p-0 sm:flex-col sm:align-start min-h25">
                <div className="text-upper retro-gaming">
                  {stakedNftsList.length} crocs staked
                </div>
                <div className="text-gradient-bg">
                  <h2
                    className={`${
                      getTotalRewards() > 0
                        ? `text-gradient`
                        : `text-gradient-no-animation`
                    }`}
                  >
                    +{getTotalRewards()} $PAPER REWARDS AVAILABLE
                  </h2>
                </div>
              </CardHeader>

              <div className="sorting-block mt-3">
                <ButtonGroup>
                  <Button
                    outline
                    onClick={() => tokenClaimHandler()}
                    className={`${
                      tokenClaimLoader ? `is-selected is-loading` : ``
                    }`}
                    disabled={
                      !stakedNftsList.length ||
                      !selectedClaimTokenIdsList.length ||
                      tokenClaimLoader
                        ? true
                        : false
                    }
                  >
                    <span>Claim</span>
                  </Button>
                  <Button
                    outline
                    onClick={() => tokenClaimUnstakeHandler()}
                    className={`${
                      tokenClaimUnstakeLoader ? `is-selected is-loading` : ``
                    }`}
                    disabled={
                      !stakedNftsList.length ||
                      !selectedClaimTokenIdsList.length ||
                      tokenClaimUnstakeLoader
                        ? true
                        : false
                    }
                  >
                    <span>Claim &amp; unstake selected</span>
                  </Button>
                  <Button
                    outline
                    onClick={() =>
                      stakedTokenSelectHandler(
                        "0",
                        true,
                        selectedClaimTokenIdsList.length ===
                          stakedNftsList.length
                      )
                    }
                    disabled={stakedNftsList.length ? false : true}
                  >
                    <span>
                      {selectedClaimTokenIdsList.length ===
                      stakedNftsList.length
                        ? `Unselect All`
                        : `Select All`}
                    </span>
                  </Button>
                </ButtonGroup>
              </div>

              <CardBody className="d-flex">
                {fetchAccountSummaryLoader && (
                  <p className="text-upper retro-gaming fs-14 mb-3">
                    LOADING...
                  </p>
                )}
                {!fetchAccountSummaryLoader && stakedNftsList.length > 0 && (
                  <ul className="staked-list">
                    {stakedNftsList.map((ownedNftObj, key) => (
                      <li
                        className="staked-list-item staked-list-item-sm"
                        key={key}
                      >
                        <StakedDataBox
                          mainImage={ownedNftObj.imageUrl}
                          labelNumber={`#${ownedNftObj.id}`}
                          days={`${ownedNftObj.daysLocked} DAYS`}
                          paperCount={`+${ownedNftObj.unclaimedRewards} $PAPER`}
                          labelText={`${StakeType[
                            ownedNftObj.pool
                          ].label.toUpperCase()}`}
                          coinImage={StakeType[ownedNftObj.pool].image}
                          propgressBarPerValue={ownedNftObj.daysCoveredPercent.toString()}
                          propgressBarValue={`${ownedNftObj.daysCoveredPercent}%`}
                          isSelected={selectedClaimTokenIdsList.includes(
                            ownedNftObj.id
                          )}
                          tokenSelectHandler={() =>
                            stakedTokenSelectHandler(
                              ownedNftObj.id,
                              false,
                              false
                            )
                          }
                          poolClass={getPoolColorClass(ownedNftObj.pool)}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </CardBody>
              <div className="harvesting-box-bottom"></div>
            </Card>
          </Col>

          <Col lg={6} md={6} sm={12} className="">
            <Card className="harvesting-box">
              <div className="harvesting-box-top"></div>
              <CardHeader className="d-flex align-items-center justify-content-between border-0 p-0 sm:flex-col sm:align-start min-h25">
                <div className="text-upper retro-gaming">
                  {ownedNftsList.length} crocs found in your wallet
                </div>
              </CardHeader>
              {isTokenApproved && (
                <div className="sorting-block mt-3">
                  <ButtonGroup>
                    <Button
                      outline
                      disabled={
                        ownedNftsList.length && selectedTokenIdsList.length
                          ? false
                          : true
                      }
                      onClick={() =>
                        confirmTokenStakeHandler(
                          StakeType[1].pool as keyof typeof StakeType
                        )
                      }
                      className={`${
                        tokenStakeType === StakeType[1].pool
                          ? `is-selected is-loading`
                          : ``
                      }`}
                    >
                      <span className="size-24">
                        <img
                          src={DesertRose}
                          alt={StakeType[1].label}
                          className="img-fluid"
                        />
                      </span>
                      <span>{StakeType[1].label}</span>
                    </Button>

                    <Button
                      outline
                      disabled={
                        ownedNftsList.length && selectedTokenIdsList.length
                          ? false
                          : true
                      }
                      onClick={() =>
                        !validateEndDateDuration(
                          StakeType[2].pool as keyof typeof StakeType
                        )
                          ? confirmTokenStakeHandler(
                              StakeType[2].pool as keyof typeof StakeType
                            )
                          : null
                      }
                      className={`${
                        tokenStakeType === StakeType[2].pool
                          ? `is-selected is-loading`
                          : ``
                      } ${
                        validateEndDateDuration(
                          StakeType[2].pool as keyof typeof StakeType
                        )
                          ? `disabled`
                          : ``
                      }`}
                    >
                      <span className="size-24">
                        <img
                          src={CruzersDemise}
                          alt={StakeType[2].label}
                          className="img-fluid"
                        />
                      </span>
                      <span>{StakeType[2].label}</span>
                    </Button>
                    <Button
                      outline
                      disabled={
                        ownedNftsList.length && selectedTokenIdsList.length
                          ? false
                          : true
                      }
                      onClick={() =>
                        !validateEndDateDuration(
                          StakeType[3].pool as keyof typeof StakeType
                        )
                          ? confirmTokenStakeHandler(
                              StakeType[3].pool as keyof typeof StakeType
                            )
                          : null
                      }
                      className={`${
                        tokenStakeType === StakeType[3].pool
                          ? `is-selected is-loading`
                          : ``
                      }  ${
                        validateEndDateDuration(
                          StakeType[3].pool as keyof typeof StakeType
                        )
                          ? `disabled`
                          : ``
                      }`}
                    >
                      <span className="size-24">
                        <img
                          src={PicanteRuins}
                          alt={StakeType[3].label}
                          className="img-fluid"
                        />
                      </span>
                      <span>{StakeType[3].label}</span>
                    </Button>
                    <Button
                      outline
                      disabled={ownedNftsList.length ? false : true}
                      onClick={() =>
                        tokenSelectHandler(
                          "0",
                          true,
                          selectedTokenIdsList.length ===
                            ownedNftsList.length ||
                            selectedTokenIdsList.length >=
                              AppConfig.MAXIMUM_SELECT_NFT
                        )
                      }
                    >
                      <span>
                        {selectedTokenIdsList.length === ownedNftsList.length ||
                        selectedTokenIdsList.length >=
                          AppConfig.MAXIMUM_SELECT_NFT
                          ? `Unselect All`
                          : `Select ${
                              ownedNftsList.length >
                              AppConfig.MAXIMUM_SELECT_NFT
                                ? AppConfig.MAXIMUM_SELECT_NFT
                                : `All`
                            }`}
                      </span>
                    </Button>
                  </ButtonGroup>
                </div>
              )}

              {!isTokenApproved && (
                <div className="sorting-block mt-3">
                  <ButtonGroup>
                    <Button
                      outline
                      onClick={() => approveTokenHandler()}
                      className={`${
                        approveTokenLoader ? `is-selected is-loading` : ``
                      }`}
                    >
                      <span>Approve Transfer</span>
                    </Button>
                  </ButtonGroup>
                </div>
              )}
              <CardBody className="d-flex">
                {fetchAccountSummaryLoader && (
                  <p className="text-upper retro-gaming fs-14 mb-3">
                    LOADING...
                  </p>
                )}
                {!fetchAccountSummaryLoader && ownedNftsList.length > 0 && (
                  <ul className="staked-list">
                    {ownedNftsList.map((stakedNftObj, key) => (
                      <li
                        className="staked-list-item staked-list-item-sm"
                        key={key}
                      >
                        <StakedDataBox
                          mainImage={stakedNftObj.imageUrl}
                          labelNumber={`#${stakedNftObj.id}`}
                          days="0 DAYS"
                          paperCount="+0 $PAPER"
                          labelText="PAPERHANDS"
                          coinImage={Coin2x2}
                          propgressBarPerValue="0"
                          propgressBarValue="0%"
                          isTermActive={true}
                          poolClass={"label-paperhands"}
                          isSelected={selectedTokenIdsList.includes(
                            stakedNftObj.id
                          )}
                          tokenSelectHandler={() =>
                            tokenSelectHandler(stakedNftObj.id, false, false)
                          }
                          isSelectedDisabled={false}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </CardBody>
              <div className="harvesting-box-bottom"></div>
            </Card>
          </Col>
        </Row>
      </Container>

      <ConfirmationModal
        isOpen={confirmModalOpen}
        modalToggler={setConfirmModalOpen}
        transaction={transaction}
        modalStatus={modalStatus}
        modalErrorMessage={modalErrorMessage}
      />
    </section>
  );
}

export default StakedCard;
