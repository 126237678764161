import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AppRouteComponent from "./components/AppRoute";
import RouteConstants from "./config/Constant";
import ScrollToTop from "./utils/ScrollToTop";
import ContextProvider from "./contexts/ContextProvider";

// Import Sections
import LandingPage from "./pages/landing/index";

function App() {
  return (
    <ContextProvider>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route>
              <Switch>
                <AppRouteComponent
                  exact
                  path={RouteConstants.LANDING_PAGE}
                  component={LandingPage}
                />
              </Switch>
            </Route>
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </ContextProvider>
  );
}

export default App;
