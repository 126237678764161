import { useContext, Fragment, useState, useEffect, useCallback } from "react";

import { Context } from "../../contexts/ContextProvider";
import GeneralApi from "../../api/GeneralApi";

// Import Sections
import Harvesting from "./partials/Harvesting";
import StakedCard from "./partials/StakedCard";
import ConnectWallet from "./partials/ConnectWallet";
import { toast } from "react-toastify";
import { IHarvestDataResponse } from "../../api/types/general";

const LandingPage = () => {
  const { walletAddress, appWeb3Modal } = useContext(Context);
  const [fetchStakeLoader, setFetchStakeLoader] = useState<boolean>(false);
  const [stakePercentage, setStakePercentage] = useState<number>(0);
  const [totalStake, setTotalStake] = useState<number>(0);
  const [totalNFTs, setTotalNFTs] = useState<number>(0);
  const [showDataSection, setShowDataSection] = useState<boolean>(false);
  const [harvestingData, setHarvestingData] =
    useState<IHarvestDataResponse | null>(null);

  const disconnectWallet = useCallback(async () => {
    if (appWeb3Modal) {
      await appWeb3Modal.clearCachedProvider();
    }
    localStorage.clear();
    window.location.reload();
  }, [appWeb3Modal]);

  const fetchStakeDetail = useCallback(
    async (onlyUpdateHarvestData: boolean = false) => {
      try {
        if (!onlyUpdateHarvestData) {
          setFetchStakeLoader(true);
        }

        const response = await GeneralApi.getStakeDetail();

        setStakePercentage(response.data.totalStakedPercentage);
        setTotalStake(response.data.totalStaked);
        setTotalNFTs(response.data.totalNFTs);
        if (walletAddress) {
          setHarvestingData(response.data.harvestingData);
          setShowDataSection(true);
        } else {
          setFetchStakeLoader(false);
        }
      } catch (err: any) {
        toast.error(err.message, {
          autoClose: 5000,
        });
        if (walletAddress) {
          disconnectWallet();
        }
      } finally {
        if (!onlyUpdateHarvestData) {
          setTimeout(() => {
            setFetchStakeLoader(false);
          }, 3000);
        }
      }
    },
    [disconnectWallet, walletAddress]
  );

  useEffect(() => {
    if (walletAddress) {
      fetchStakeDetail();
    }
  }, [walletAddress, fetchStakeDetail]);

  useEffect(() => {
    fetchStakeDetail();
  }, [fetchStakeDetail]);

  return (
    <div className="w-full" id="Home">
      {(!walletAddress || fetchStakeLoader) && (
        <ConnectWallet
          fetchStakeLoader={fetchStakeLoader}
          stakePercentage={stakePercentage}
          totalStake={totalStake}
          totalNFTs={totalNFTs}
        />
      )}

      {walletAddress && !fetchStakeLoader && showDataSection && (
        <Fragment>
          <Harvesting harvestingData={harvestingData} />
          <StakedCard fetchStakeDetail={fetchStakeDetail} />
        </Fragment>
      )}
    </div>
  );
};

export default LandingPage;
