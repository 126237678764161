import { Card, CardHeader, CardBody } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { getColorClassArray } from "../../../../utils/common-utils";

interface Props {
  image: string;
  altText: string;
  cardImage: string;
  labelHeading: string;
  labelSubHeading: string;
  totalNumber: number;
  totalValue: string;
  oneWeekStat: Array<number>;
  isPositive: boolean | string;
}

const HarvestingDataBox = (props: Props) => {
  const options = {
    chart: {
      type: "line",
      width: 138,
      height: 42,
      backgroundColor: "#1D2125",
    },
    credits: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    accessibility: false,
    title: false,
    subtitle: false,
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    tooltip: {
      outside: true,
      shared: true,
      useHTML: true,
      headerFormat: null,
      borderColor: "#1D2125",
      backgroundColor: "#000",
      style: {
        color: "#fff",
        fontFamily: "VCR_OSD_MONO_1_001",
      },
      pointFormat:
        '<div class="arrow_box highchart-tooltip">' +
        "<span>{point.y:.0f}</span>" +
        "</div>",
    },
    plotOptions: {
      line: {
        fillOpacity: 0,
        color: getColorClassArray(props.oneWeekStat) ? "#1DD1A1" : "#DE5A5A",
        label: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        showInLegend: false,
        name: "Harvesting",
        data: props.oneWeekStat,
      },
    ],
  };

  return (
    <Card className="harvesting-box sm:mmb-5">
      <div className="harvesting-box-top"></div>
      <CardHeader className="d-flex align-items-center border-0 p-0">
        <div className="size-40">
          <LazyLoadImage
            effect="blur"
            alt={props.altText}
            src={props.image}
            srcSet={props.image}
          />
        </div>
        <div className="harvesting-details">
          <span>{props.labelHeading}</span>
          <span>{props.labelSubHeading}</span>

          <div className="size-50 position-absolute top-2px right-0">
            <LazyLoadImage
              effect="blur"
              alt={props.altText}
              src={props.cardImage}
              srcSet={props.cardImage}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column">
          <span className="text-upper retro-gaming">{props.totalNumber}</span>
          <span
            className={`${props.isPositive ? `text-success` : `text-danger`}`}
          >
            {props.totalValue}
          </span>
        </div>
        <div className="ml-auto">
          <div className="size-138">
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </div>
      </CardBody>
      <div className="harvesting-box-bottom"></div>
    </Card>
  );
};

export default HarvestingDataBox;
