interface Props {
  message: string;
  link?: string;
}

const CustomToastComponent = ({ message, link }: Props) => {
  return (
    <div className="d-flex align-items-start flex-column">
      <h6 className="mb-1">{message}</h6>
      {link && (
        <div className="external-link">
          <a href={link} target="_blank" rel="noreferrer" className="link-view">
            View on explorer
          </a>
        </div>
      )}
    </div>
  );
};

export default CustomToastComponent;
