import { AxiosResponse } from "axios";
import BaseApi from "./BaseApi";
import {
  IStakeResponse,
  IAccountSummaryResponse,
  IStakeTokenResponse,
} from "./types/general";

const GeneralApi = {
  getStakeDetail: async (): Promise<AxiosResponse<IStakeResponse>> => {
    return await BaseApi.getRequest(`/get-staked-details`);
  },
  getAccountNftsSummary: async (
    walletAddress: string,
    getOnlyStakedList: number
  ): Promise<AxiosResponse<IAccountSummaryResponse>> => {
    return await BaseApi.getRequest(
      `/get-account-nfts?walletAddress=${walletAddress}&getOnlyStakedList=${getOnlyStakedList}`
    );
  },
  stakeNfts: async (
    walletAddress: string,
    tokenIds: string,
    stakeType: number
  ): Promise<AxiosResponse<IStakeTokenResponse>> => {
    return await BaseApi.getRequest(
      `/stake-nfts?walletAddress=${walletAddress}&tokenIds=${tokenIds}&type=${stakeType}`
    );
  },
  claimNfts: async (
    walletAddress: string,
    tokenIds: string
  ): Promise<AxiosResponse<IStakeTokenResponse>> => {
    return await BaseApi.getRequest(
      `/claim-nfts?walletAddress=${walletAddress}&tokenIds=${tokenIds}`
    );
  },
  claimUnstakeNfts: async (
    walletAddress: string,
    tokenIds: string
  ): Promise<AxiosResponse<IStakeTokenResponse>> => {
    return await BaseApi.getRequest(
      `/claim-stake-nfts?walletAddress=${walletAddress}&tokenIds=${tokenIds}`
    );
  },
};

export default GeneralApi;
