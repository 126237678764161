export const getColorClassArray = (value: Array<number>) => {
  const first = value.length > 0 ? value[0] : 0;
  const last = value.length > 0 ? value[value.length - 1] : 0;
  return last >= first ? true : false;
};

export const getpercentageLabel = (value: number, getBoolean: boolean) => {
  return Math.sign(value) >= 0
    ? getBoolean
      ? true
      : `+${value}%`
    : getBoolean
    ? false
    : `${value}%`;
};
