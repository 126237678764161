import { useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Context } from "../../../contexts/ContextProvider";

// Static Assets
import PrepareLogo from "../../../assets/images/prepare-logo@2x.png";

interface Props {
  fetchStakeLoader: boolean;
  stakePercentage: number;
  totalStake: number;
  totalNFTs: number;
}

function ConnectWallet({
  fetchStakeLoader,
  stakePercentage,
  totalStake,
  totalNFTs,
}: Props) {
  const { showLoader, connectWalletHandler } = useContext(Context);

  return (
    <section className="connect-wallet connect-wallet-sm z-over">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col lg={8} md={9} sm={12} className="">
            <LazyLoadImage
              effect="blur"
              alt="Prepare For Harvest"
              src={PrepareLogo}
              srcSet={PrepareLogo}
            />
            <div className="d-flex justify-content-center flex-column mx-auto">
              <div className="start-block start-block-sm">
                <div className="">
                  <span>{stakePercentage}% STAKED</span>
                  <span>
                    {totalStake} / {totalNFTs.toLocaleString()}
                  </span>
                </div>
              </div>
              <button
                className={`connect-wallet-button connect-wallet-button-sm ${
                  showLoader || fetchStakeLoader ? `is-loading` : ``
                }`}
                onClick={() => connectWalletHandler()}
              >
                CONNECT WALLET
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ConnectWallet;
