import React from 'react'

// Static UI Imports
import Header from './partials/Header'
// import Footer from './partials/Footer'

interface Props {
  children: React.ReactChild | React.ReactChildren
}

interface State {}

class UnauthenticatedLayout extends React.Component<Props, State> {
  render() {
    return (
      <div className="full-page">
        <Header />
        <main>{this.props.children}</main>
      </div>
    )
  }
}

export default UnauthenticatedLayout
