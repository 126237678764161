import { ethers } from "ethers";
import { FC, createContext, useState, useEffect } from "react";
import Web3Modal from "web3modal";
import { toast } from "react-toastify";

import { ContextState } from "./types";
import { NetworkId } from "../config/Constant";
import AppConfig from "../config/config";

import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

const contextDefaultValues: ContextState = {
  walletAddress: "",
  showLoader: false,
  resetContext: false,
  networkId: "",
  requestInProcess: false,
  web3Provider: null,
  appWeb3Modal: null,
  updateShowLoader: () => {},
  updateWalletAddress: () => {},
  updateNetworkId: () => {},
  updateResetContext: () => {},
  updateRequestInProcess: () => {},
  updateWeb3Provider: () => {},
  connectWalletHandler: () => {},
  disconnectWallet: () => {},
};

export const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions: {
    walletlink: {
      package: CoinbaseWalletSDK,
      options: {
        appName: "Acrocalypse",
        infuraId: AppConfig.INFURA_ID,
      },
    },
  },
  theme: {
    background: "rgb(18, 18, 18)",
    main: "rgb(223, 224, 226)",
    secondary: "rgb(179, 180, 188)",
    border: "rgba(54, 56, 69, 1)",
    hover: "rgb(13, 13, 15)",
  },
});

export const Context = createContext<ContextState>(contextDefaultValues);

const ContextProvider: FC = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState<string>(
    contextDefaultValues.walletAddress
  );
  const [showLoader, setShowLoader] = useState<boolean>(
    contextDefaultValues.showLoader
  );
  const [networkId, setNetworkId] = useState<string>(
    contextDefaultValues.networkId
  );
  const [resetContext, setResetContext] = useState<boolean>(
    contextDefaultValues.resetContext
  );
  const [appWeb3Modal, setAppWeb3Modal] = useState<any>(
    contextDefaultValues.appWeb3Modal
  );
  const [requestInProcess, setRequestInProcess] = useState<boolean>(
    contextDefaultValues.requestInProcess
  );
  const [web3Provider, setWeb3Provider] =
    useState<ethers.providers.Web3Provider | null>(null);

  const updateWalletAddress = (walletAddress: string) =>
    setWalletAddress(walletAddress);
  const updateShowLoader = (showLoader: boolean) => setShowLoader(showLoader);
  const updateNetworkId = (networkId: string) => setNetworkId(networkId);
  const updateResetContext = (resetContext: boolean) => {
    setResetContext(resetContext);
  };
  const updateRequestInProcess = (requestInProcess: boolean) =>
    setRequestInProcess(requestInProcess);
  const updateWeb3Provider = (provider: ethers.providers.Web3Provider) => {
    setWeb3Provider(provider);
  };

  const connectWalletHandler = async (type: string = "") => {
    updateShowLoader(true);

    let web3Provider: any;
    if (type !== "defi") {
      setAppWeb3Modal(web3Modal);
      if (web3Modal.cachedProvider) {
        web3Provider = await web3Modal.connect();
      } else {
        web3Provider = await web3Modal
          .connect()
          .then((web3provider: any) => web3provider)
          .catch((error: any) => {
            updateShowLoader(false);
            return null;
          });
      }
    }

    if (!web3Provider) {
      toast.error("No provider found", {
        autoClose: 10000,
      });
      return;
    }

    await subscribeProvider(web3Provider);

    try {
      const provider = new ethers.providers.Web3Provider(web3Provider);

      // verify chain id/network id
      const cid = await web3Provider.request({
        method: "net_version",
      });

      const correctChain =
        parseInt(cid) === parseInt(NetworkId.mainnet) ||
        parseInt(cid) === parseInt(NetworkId.ethereum) ||
        parseInt(cid) === parseInt(NetworkId.hardhat);
      if (!correctChain) {
        toast.error("You are on the wrong network. Please select mainnet.", {
          autoClose: 20000,
        });
        updateShowLoader(false);
        return;
      }

      updateNetworkId(cid);
      updateWeb3Provider(provider);

      const accounts = await web3Provider.request({
        method: "eth_accounts",
        params: [{ chainId: cid }],
      });

      const address = accounts[0];

      const localStorageWalletAddress = localStorage.getItem(
        AppConfig.LOCAL_STORAGE_WALLET_KEY
      );

      if (!localStorageWalletAddress) {
        localStorage.setItem(AppConfig.LOCAL_STORAGE_WALLET_KEY, address);
      }

      updateWalletAddress(address);
      updateShowLoader(false);
    } catch (error: any) {
      toast.error(error.message);

      await web3Modal.clearCachedProvider();

      updateShowLoader(false);
    } finally {
      updateShowLoader(false);
    }
  };

  const subscribeProvider = async (web3provider: any) => {
    if (!web3provider.on) {
      return;
    }

    web3provider.on("disconnect", (_error: any) => {
      window.location.reload();
    });

    web3provider.on("accountsChanged", (accounts: any) => {
      if (accounts.length > 0) {
        updateWalletAddress(accounts[0]);
        updateResetContext(true);
      } else {
        updateWalletAddress("");
        window.location.reload();
      }
    });

    web3provider.on("chainChanged", (_chainId: any) => {
      window.location.reload();
    });
  };

  const disconnectWallet = async () => {
    if (appWeb3Modal) {
      await appWeb3Modal.clearCachedProvider();
    }
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    if (appWeb3Modal && appWeb3Modal.cachedProvider) {
      connectWalletHandler();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Context.Provider
      value={{
        walletAddress,
        updateWalletAddress,
        showLoader,
        updateShowLoader,
        networkId,
        updateNetworkId,
        resetContext,
        updateResetContext,
        requestInProcess,
        updateRequestInProcess,
        web3Provider,
        updateWeb3Provider,
        appWeb3Modal,
        connectWalletHandler,
        disconnectWallet,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
